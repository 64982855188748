import { Line } from "react-chartjs-2";

const chartData: (React.ComponentProps<typeof Line>)["data"] = {
  labels: ['MAR-24', 'APR-24', 'MAY-24', 'JUN-24', 'JUL-24', 'AUG-24', 'SEP-24', 'OCT-24'],
  datasets: [
    {
      data: [54067305254, 62710330980, 69813448797, 75645901217, 82134869248, 88962249792, 95144331782, 101217101622],
      borderColor: '#62C759',
      borderWidth: 2,
      backgroundColor: '#26433A',
      pointStyle: 'rect',
      pointBorderWidth: 6,
      pointHoverBorderWidth: 20,
      pointHitRadius: 20,
      fill: true,
    }
  ],
};

export default chartData;
